<template>
  <div class="box">
    <div style="display: flex;align-items: center;max-width: 60%" @click="routerBack" :class="{isActive: !userId }">
      <van-icon name="arrow-left" size="20px" style="margin-top: 3px"/>
      <div style="margin-left: 5px;max-width: 90%;white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;" >{{ title }}</div>
    </div>
    <div style="display: flex;justify-content: space-between;" :style="{width: collectionStatus ? '135px' : '120px'}">
      <div @click="collection">
        <template v-if="collectionStatus">
          <van-icon name="star" color="orange" size="20px"/>
          已收藏
        </template>
       <template v-else>
         <van-icon name="star-o" size="20px" :class="{isActive: !userId }"/>
        <span :class="{isActive: !userId }">收藏</span> 
       </template>
      </div>
      <div style="display: flex;align-items: center;" @click="errorOpen">
        <img :src="errorCorrection" style="width: 16px;height: 16px;margin-right: 3px;" :class="{isActive: !userId }">
       <span :class="{isActive: !userId }">纠错</span> 
      </div>
    </div>
    <van-popup v-model="errorVan" position="bottom" :style="{ height: '60%',width: openWidth, left: openLeft }" >
      <div class="errorLog" @click="errorLog_btn">
        查看我的纠错记录>>
      </div>
      <van-field
          ref="errorText"
          v-model="errorText"
          rows="1"
          autosize
          type="textarea"
          style="height: 130px;width: 95%;margin: 15px auto 0 auto;border: 0"
          placeholder="请输入"
          @click="errorFocs"
      />
      <van-button @click="submit" type="info" block style="width: 95%;margin: 15px auto 0 auto;border: 0">提交</van-button>
    </van-popup>
  </div>
</template>

<script>
import errorCorrection from '@/assets/errorCorrection.png'
import {
  delFormulaCollectionApi,
  getFormulaCollection,
  postFormulaCollectionApi,
  postFormulaCollectionJCApi
} from "@/service/api";
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
export default {
  name: "FormulaBanner",
  props: {
    title:{
      type: [String, Number],
      required: true,
    },
    formulaCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      errorCorrection,
      collectionStatus: false,
      errorVan: false,
      errorText: '',
      openWidth: '100%',
      openLeft: 0,
      userId:"",
    }//dataReturn
  },
  async created() {
    await this.test()
    await this.getYesNo()
  },
  methods: {
    routerBack () {
      this.$router.go(-1)
    },
    async test(){
      //像接口头部传用户信息
      this.userId=this.$route.query.memberId
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getYesNo () {
      const data = {
        contentId: this.$router.currentRoute.query.id
      }
      const res = await getFormulaCollection(data)
      this.collectionStatus = res.code === 0;
    },
    async collection () {
      const status = !this.collectionStatus
      if (status) {
        const data = {
          contentId: this.$route.query.id,
          contentType: 'YXGS',
          channel: this.$route.query.channel,
        }
        const res = await postFormulaCollectionApi(data)
        if (res.code === 0) {
          Toast.success('收藏成功')
          this.collectionStatus = !this.collectionStatus
        } else {
          Toast.fail(res.message);
        }
      } else {
        const data = {
          contentId: this.$route.query.id,
          contentType: 'YXGS',
          channel: this.$route.query.channel,
        }
        const res = await delFormulaCollectionApi(data)
        if (res.code === 0) {
          Toast.success('已取消收藏')
          this.collectionStatus = !this.collectionStatus
        } else {
          Toast.fail(res.message);
        }
      }
    },
    errorOpen () {
      const app = document.getElementById('app')
      this.openWidth = app.offsetWidth + 'px'
      this.openLeft = app.offsetLeft + 'px'
      this.errorVan = true
    },
    errorFocs () {
      this.$refs.errorText.focus()
    },
    async submit () {
      if (this.errorText.trim().length === 0) {
        Toast.fail('内容不能为空')
        return
      }
      const data = {
        content: this.errorText,
        formulaId: this.$route.query.id,
        formulaCode: this.formulaCode
      }
      const res = await postFormulaCollectionJCApi(data)
      if (res.code === 0) {
        Toast.success('提交成功')
        this.errorText = ''
        this.errorVan = false
      } else {
        Toast.fail(res.message)
      }
    },
    errorLog_btn(){
      this.$router.push({path:'/medicalErrorLog'});
    },
  }
}
</script>

<style scoped>
 .box {
   height: 50px;
   background-color: white;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 15px;
   padding-left: 9px;
 }
 .van-popup {
   background-color: #F6F6F6;
 }
 .van-button::before {
   border-radius: 160px;
   overflow: hidden;
 }
 .errorLog{
   text-align: right;
   font-size: 14px;
   margin-top: 10px;
   margin-right: 10px;
   color: #0a84ff;
 }
 .isActive {
  color: rgb(98, 100, 102);
}
</style>